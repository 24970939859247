import axios from 'axios'
import { Message } from 'view-design'
import router from '@/router'
import { showLoading, closeLoading } from '@/utils/loading'
import { resetTokenAndClearUser } from '@/utils'

const service = axios.create({
    baseURL: 'https://www.bjca.xyz/',
    timeout: 60000,
})

// const token = localStorage.getItem('token')

service.interceptors.request.use(config => {
    showLoading()
    const token = localStorage.getItem('token')
    if (token) {
        config.headers.Authorization = token
    }

    return config
}, (error) => Promise.reject(error))

service.interceptors.response.use(response => {
    closeLoading()
    const res = response.data
    // 这里是接口处理的一个示范，可以根据自己的项目需求更改
    // 错误处理
    if (res.code != 0 && res.msg) {
        Message.error({
            content: res.msg,
        })

        // token 无效，清空路由，退出登录
        if (res.code == 500) {
            if (res.msg == '登录已失效,请重新登录!') {
                resetTokenAndClearUser()
                router.push('login')
            }
        }

        return Promise.reject()
    }
    if (response.config.url == '/diary/api/login') {
        localStorage.setItem('token', response.headers.authorization)
    }

    // 如果接口正常，直接返回数据
    return res
}, (error) => {
    closeLoading()
    if (error.name == 'Error') {
        Message.error({
            content: error.msg,
        })
    } else {
        Message.error({
            content: error.response.data.data || error.message,
        })
    }

    return Promise.reject(error)
})

export default service
